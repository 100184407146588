import React from "react";
import { Link } from "react-router-dom";
import EventImgOne from "../../assets/img/event1.jpg";

export default function HomeEventsSection() {
  return (
    <div className="home-events-main container-fluid p-0">
      <div className="events-banner">
        <h4>upcoming events</h4>
      </div>
      <div className="events-list">
        <div className="custom-container">
          <div className="row">
            <div className="col-md-4 col-12">
              <Link to="/eventsdetail">
                <div className="events-box">
                  <img src={EventImgOne} />
                  <h6>27-09-2024</h6>
                  <p>
                    Waters Kerala Water Expo is an unparalleled gathering of
                    industry leaders, experts, and enthusiasts, aimed at
                    fostering innovation, collaboration, and growth...
                  </p>
                </div>
              </Link>
            </div>
            <div className="event-btn">
              <Link className="read-more-btn" to="/events">
                View More{" "}
                <span class="material-symbols-outlined">trending_flat</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
