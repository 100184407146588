import React from 'react';
import { Link } from 'react-router-dom';
import SliderImgOne from '../../assets/img/slider1.jpg';
import SliderContentImg from '../../assets/img/water-expo-white.png';
import SliderRegBtn from '../../assets/img/reg-btn.png';


export default function HomeSlider() {
  return (
    <div>
        <div className='home-slider'>
            <img src={SliderImgOne}/>
            <div className='slider-content'>
              <img src={SliderContentImg}/>
              <a href="https://waterskerala.com/app/expo24/index.php"><img src={SliderRegBtn}/></a>
            </div>
        </div>
    </div>
  )
}
