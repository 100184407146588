import React from "react";
import { Link } from "react-router-dom";
import EventImgOne from "../../assets/img/event1.jpg";

export default function HomeService() {
  return (
    <div className="home-service container-fluid">
      <div className="custom-container">
        <div className="row">
          <div className="col-md-4 col-12 p-0 hm-service-first">
            <div className="service-img">
              <img src={EventImgOne} />
            </div>
          </div>
          <div className="col-md-8 col-12 p-0 hm-service-second">
            <div className="service-content">
              <h4>Water Expo</h4>
              <p>
                Waters Kerala Water Expo is an unparalleled gathering of
                industry leaders, experts, and enthusiasts, aimed at fostering
                innovation, collabcation, and growth. With a diverse range of
                activities and an array of prominent speakers, it promises to be
                an extraordinary event that will leave a lasting impact on all
                attendees.
              </p>
              <a className="read-more-btn" href="https://waterskerala.com/app/expo24/index.php">
                Register Now{" "}
                <span class="material-symbols-outlined">trending_flat</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
